import { FC } from "react";
import { HEADER_LINKS } from "./constants";
import { Link } from "@nextui-org/link";

type Props = {};

export const MobileNav: FC<Props> = () => {
	return (
		<nav className="w-full">
			<ul>
				{HEADER_LINKS.map((linkProps, i) => (
					<li key={i} className="w-full">
						<Link
							className="w-full justify-center py-2"
							size="lg"
							color="foreground"
							{...linkProps}
						/>
					</li>
				))}
			</ul>
		</nav>
	);
};
